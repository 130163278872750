import { Link } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
import Header from '../components/Header'
import Footer from '../components/Footer'

// @ts-ignore
import denmarkImg from '../img/dk.png'
// @ts-ignore
import finlandImg from '../img/f.png'
// @ts-ignore
import norwayImg from '../img/n.png'
// @ts-ignore
import swedenImg from '../img/s.png'

export default () => {
  return (
    <main className="frontpage">
      <Helmet>
        <title>MOP Floorcheck</title>
        <link rel="canonical" href="https://mopfloorcheck.com" />
      </Helmet>
      <Header tab="home" />
      <section className="intro">
        <div className="intro-content">
          <h2>{new Date().getFullYear() - 1998} YEARS OF EXPERIENCE</h2>
          <div className="caption">
            Constantly challenging ourselves to deliver more to our clients
          </div>
          <div className="buttons">
            <Link className="fusion-btn" to="/contact">Let's get started</Link>
          </div>
        </div>
      </section>
      <section className="about infobox-section">
        <div className="container">
          <div className="infobox row">
            <div className="col-md-8">
              <h3>About MOP Floorcheck</h3>
              <p>MOP Floorcheck is part of the MOP Group. We have provided stock audits on behalf of financial institutions and importers since 1998, primarily auditing cars, caravans, motorcycles, and boats. We currently operate within Denmark, Finland, Norway, and Sweden, performing more than 1,200 audits every month.</p>
            </div>
            <div className="col-md-4">
              <h3>Why MOP Floorcheck?</h3>
              <ul>
                <li>Competitive flatrate price</li>
                <li>{new Date().getFullYear() - 1998} years of experience</li>
                <li>External audits yield better results</li>
              </ul>
            </div>
          </div>
          <div className="about-links infobox row">
            <div className="col-md">
              <h3>WHO WE ARE</h3>
              <p>We have performed stock audits since 1998. Our main office is located in Copenhagen, Denmark.</p>
              <Link to="/who-we-are" className="fusion-btn">LEARN MORE</Link>
            </div>
            <div className="col-md">
              <h3>SERVICES</h3>
              <p>We perform stock audits on products identifiable by chassis number etc.</p>
              <Link to="/services" className="fusion-btn">LEARN MORE</Link>
            </div>
            <div className="col-md">
              <h3>CONTACT</h3>
              <p>Contact us for a non-committal offer and learn how MOP can benefit your company today.</p>
              <Link to="/contact" className="fusion-btn">LEARN MORE</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="got-questions">
        <div className="container">
          <h2>GOT QUESTIONS? DON’T HESITATE TO ASK.</h2>
          <Link className="fusion-btn" to="/contact">CONTACT US</Link>
        </div>
      </section>
      <section className="countries">
        <div className="container">
          <h2>WE COVER THE NORDIC COUNTRIES</h2>
          <hr />
          <div className="row">
            <div className="col-lg">
              <img src={denmarkImg} />
                Denmark
              </div>
            <div className="col-lg">
              <img src={finlandImg} />
                Finland
              </div>
            <div className="col-lg">
              <img src={norwayImg} />
                Norway
              </div>
            <div className="col-lg">
              <img src={swedenImg} />
                Sweden
              </div>
          </div>
        </div>
      </section>
      <section className="quote">
        <blockquote>
          <q>Our greatest desire is to be our clients’ most valuable partner.</q>
        </blockquote>
        <div className="author">
          Patrick Olsen • CEO
          </div>
      </section>
      <Footer />
    </main>
  )
}
